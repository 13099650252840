<template>
	<div>
		<div class="main-filter">
			<el-form inline :model="formInline" class="demo-form-inline">
				<el-form-item label="中药名称：">
					<el-autocomplete
						v-model.trim="formInline.name"
						:fetch-suggestions="querySearchAsync"
						clearable
						placeholder="请输入内容"
						@select="handleSelect"
					></el-autocomplete>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="onSearch" icon="el-icon-search">搜索</el-button>
				</el-form-item>
			</el-form>
		</div>
		<div class="main-content">
			<div class="main-content-title">
				<p>共有 {{ total }} 条结果</p>
				<el-button type="primary" @click="modifyParkInfo($event, false)" icon="el-icon-plus">新增</el-button>
			</div>
			<Table
				:loading="loading"
				:table-data="tableData"
				:total="total"
				:table-head="tableHead"
				:current-page.sync="queryData.current"
				:page-size.sync="queryData.size"
				:operation-column-width="140"
				:is-show-selection="false"
				:showOperation="true"
				@change-page="getList"
				:tableHeight="tableHeight"
			>
				<!-- 插槽操作栏固定属性operation -->
				<template #enabled="{ scope }">
					<el-switch inactive-text="否" active-text="是" v-model="scope.row.enabled" @change="changeOpen(scope.row)"> </el-switch>
				</template>
				<template #operation="{ scope }">
					<el-button @click="modifyParkInfo($event, true, scope.row)" size="mini" type="primary"> 查看 </el-button>
					<el-button
						@click="modifyParkInfo($event, false, scope.row)"
						size="mini"
						:type="scope.row.enabled ? 'info' : 'warning'"
						:disabled="scope.row.enabled"
					>
						修改
					</el-button>
				</template>
			</Table>
		</div>
		<createView ref="createView" :isShowDetail="isShowDetail" @getList="getList"></createView>
	</div>
</template>

<script>
export default {
	name: 'traditionalInfo',
	components: {
		Table: () => import('@/components/Table/table'),
		createView: () => import('./newDialog.vue'),
	},
	data() {
		return {
			formInline: {
				name: '',
			},
			tableHead: [
				{
					label: '中药名称',
					prop: 'name',
					formatter: (row) => {
						return row.name || '-';
					},
				},
				{
					label: '类别',
					prop: 'typeName',
					formatter: (row) => {
						return row.typeName || '-';
					},
				},
				{
					label: '功效',
					prop: 'attention',
					formatter: (row) => {
						return row.attention || '-';
					},
				},
				{
					label: '创建时间',
					prop: 'createTime',
					formatter: (row) => {
						return row.createTime || '-';
					},
				},
				{
					label: '是否启用',
					prop: 'enabled',
					width: 110,
				},
			],
			loading: false,
			queryData: {
				current: 1,
				size: 10,
			},
			total: 0,
			tableData: [],
			options: [],
			isShowDetail: false,
			link: '',
		};
	},
	computed: {
		tableHeight() {
			return window.innerHeight - 300;
		},
	},
	mounted() {
		this.getList();
	},
	methods: {
		// 查询条件
		onSearch() {
			this.queryData.current = 1;
			this.getList(null);
		},
		handleSelect(subInput) {
			this.formInline.name = subInput.name;
		},
		changeOpen(row) {
			this.$http
				.put(row._links.update.href, row)
				.then((res) => {
					if (res.data && res.data.success) {
						this.$message.success('操作成功');
						this.getList();
					}
				})
				.catch((e) => {});
		},
		// 搜索建议
		querySearchAsync(queryString, cb) {
			queryString ? this.getList('filter') : '';
			clearTimeout(this.timeout);
			this.timeout = setTimeout(() => {
				cb(this.options);
			}, 1000 * Math.random());
		},
		//获取列表
		getList(type = '') {
			type == 'filter' ? '' : (this.loading = true);
			let data = {
				...this.formInline,
				...this.queryData,
				medicineType: 'C',
			};
			this.$http
				.get(this.api['Medicines#index'].href, { params: data })
				.then((res) => {
					if (res.data && res.data.success) {
						if (type == 'filter') {
							this.options = res.data.collection.map((item) => {
								return {
									...item,
									value: item.name,
								};
							});
						} else {
							this.link = res.data._links?.create?.href || '';
							res.data.collection = res.data.collection.map((item) => {
								return {
									...item,
									typeName:
										item.medicineCategorizing &&
										item.medicineCategorizing[0].name +
											'-' +
											item.medicineCategorizing.find((v) => v.children && v.children.length > 0).children[0].name,
								};
							});
							this.tableData = res.data.collection || [];
							this.total = res.data.pagination.totalItems || 0;
							this.loading = false;
						}
					}
				})
				.catch((e) => {
					this.loading = false;
				});
		},
		modifyParkInfo(event, isShowDetail, row) {
			let data = {
				row: row || null,
				link: row ? row._links.update.href : this.link,
			};
			this.$refs.createView.init(data);
			this.isShowDetail = isShowDetail;
		},
	},
	created() {},
};
</script>

<style lang="scss" scoped>
.demo-form-inline {
	/deep/ .el-select {
		width: 120px !important;
		.el-input {
			width: 120px !important;
			.el-input__inner {
				width: 120px !important;
			}
		}
	}
	/deep/ .el-input {
		width: 160px;
	}
}
.main-content {
	&-title {
		line-height: 48px;
		display: flex;
		justify-content: space-between;
		.el-button {
			line-height: 32px;
			padding: 0 16px;
			height: 32px;
			margin-top: 8px;
		}
	}
}
</style>